<template>
  <div class="flex flex-col flex-grow mt-5">
    <p class="font-thin mb-8 leading-tight text-mbs-black text-13">
      Enter the ticket id and password sent to your phone number.
    </p>
    <form class="flex flex-col flex-grow" @submit.prevent="submit">
      <form-group
        name="ticket_no"
        :form="form"
        v-model="form.data.ticket_id.value"
      >
        Ticket ID
      </form-group>

      <form-group
        name="password"
        :form="form"
        v-model="form.data.password.value"
      >
        Password
      </form-group>

      <p class="-mt-4 text-11">
        <strong>Please note</strong>: Your ticket ID and Passcode might be delayed by your bank.
        If issue persists after 10 minutes, kindly explore other options.
      </p>

      <p
        v-if="timer.isDone"
        class="my-5 text-center text-13 text-mbs-black"
      >
        Didn’t get SMS?
        <span
          class="font-bold text-blue-500 underline cursor-pointer"
          @click="selectAnotherMethod"
        >
          Use another method
        </span>
      </p>

      <p v-else class="my-5 text-center text-13 text-mbs-black">
        Resend in {{ timer.time }} secs
      </p>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-5">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="flex gap-4 mt-auto">
        <button
          type="button"
          class="relative btn btn-gray-soft btn-md"
          :disabled="form.loading"
          @click="back"
        >
          <span>Back</span>
          <i
            class="absolute inline-flex left-20px top-1/2"
            style="transform: translateY(-50%);"
          >
            <ion-icon name="arrow-back-outline" />
          </i>
        </button>
        <button
          type="submit"
          class="relative w-full btn btn-blue btn-md"
          :disabled="form.loading"
        >
          <sm-loader v-if="form.loading" class="sm-loader-white" />
          <span v-else>Proceed</span>
          <i
            class="absolute inline-flex right-20px top-1/2"
            style="transform: translateY(-50%);"
          >
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'StatementConfirmation',
  props: {
    bankData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'account_no', rules: 'required|number|length:10' },
        { name: 'bank_id', rules: 'required' },
        { name: 'phone', rules: 'required|phone' },
        { name: 'ticket_id', rules: 'required' },
        { name: 'password', rules: 'required' }
      ]),
      timer: {
        time: 120,
        interval: null,
        isDone: true
      }
    };
  },
  mounted() {
    this.startCountDown();
    this.mapData();
  },
  beforeDestroy() {
    this.stopCountDown();
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    back() {
      this.$emit('back');
    },
    mapData() {
      Object.keys(this.form.data).forEach((key) => {
        this.form.data[key].value = this.bankData[key] || '';
      });
    },
    selectAnotherMethod() {
      this.$emit('select-different-method');
    },
    startCountDown() {
      this.stopCountDown(this.timer);

      this.timer.isDone = false;
      this.timer.interval = setInterval(() => {
        this.timer.time -= 1;
        if (this.timer.time <= 0) {
          this.stopCountDown(this.timer);
          this.timer.isDone = true;
          this.timer.time = 60;
        }
      }, 1000);
    },
    stopCountDown() {
      clearTimeout(this.timer.interval);
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.loading = true;
      await this.sendRequest('personal.onboarding.documents.confirmMbs', {
        data: this.getFormData(this.form),
        success: (response) => {
          this.stopCountDown();
          const {
            data: { user, documents }
          } = response.data;
          this.updateUser({ ...user, documents }).then(() => {
            this.$emit('confirm-statement', { user, documents });
          });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.loading  = false;
    },
  }
};
</script>
